// Variables
@import "../variables";

body{
  background: #fff;
}
a{
  color: #999;
}
a:hover{
  color: #777;
}
#login-layout #app{
  position: absolute;
  width: 100%;
  height: 100%;
}
#login-layout #app .container{
  height: 100%;
}
#login-layout #app .form-container{
  padding: 0 15px;
}
#login-layout #app .login-box-container{
  display: table;
  height: 100%;
  margin: 0px auto;
}
#login-layout #app .login-box{
  width: 414px;
  max-width: 100%;
  margin: 0px auto;

  display: table-cell;
  vertical-align: middle;
}
#login-layout #app .login-logo{
  margin:0px auto;
  max-width: 90%;
}
#login-layout #app .card{
  border: none;
}
#login-layout #app .card-header{
  border:none;
  background-color: #fff;
}
#login-layout #app .form-title{
  font-size: 18px;
  margin: 30px auto;
}
#login-layout #app .login-button{
  margin:10px 0 100px;
}
.login-text{
  font-weight:600;
}

@media screen and (max-width: 767px){
  #login-layout #app .container, #login-layout #app .login-box{
    display: block;
    width: auto;
  }
}